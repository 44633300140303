import * as React from "react";
import CalloutBanner from "../components/fullWidthBanner/callout/index.js";
import Gallery from "../components/gallery/index.js";
import HeroComponent from "../components/hero/index.js";
import Layout from "../components/Layout.js";
import UpcomingEvent from "../components/upcomingEvent/index.js";
import beachday from "../images/events/beachday.jpeg";
import brainteasers from '../images/events/sig.jpg';
import bb1 from '../images/events/bb/bb1.jpeg';
import bb2 from '../images/events/bb/bb2.jpeg';
import bb3 from '../images/events/bb/bb3.jpeg';
import bb4 from '../images/events/bb/bb4.jpeg';
import wstem1 from '../images/events/wstem1.jpeg';
import unit1 from '../images/events/unit1.jpeg';
import webdev1 from '../images/events/webdev.jpeg';
import webdev2 from '../images/events/webdev2.jpeg';
import oweek from '../images/events/oweek.jpeg';


const up_coming_events = {
  name: "SIG Brain Teasers",
  day: "19",
  month: "May",
  image: brainteasers,
  description:
    "Want to impress one of the world’s largest Quantitative Trading firms with your quick thinking and problem solving skills? Come along to SIG & COMM-STEM’s Brain Teaser Battle on May  19th  and do exactly that!",
  link: "https://www.facebook.com/events/700491577982269?acontext=%7B%22event_action_history%22%3A[%7B%22surface%22%3A%22page%22%7D]%7D",
};

const professional_event1 = [
  {
    name: "Start-Up Evening!",
    image: bb1,
  },
  {
    name: "Start-Up Evening!",
    image: bb2,
  },{
    name: "Start-Up Evening!",
    image: bb3,
  }
];

const professional_event2= [
  {
    name: "Start-Up Evening!",
    image: bb4,
  },
  {
    name: "Women In STEM",
    image: wstem1,
  },
  {
    name: "STEM in Trading",
    image: unit1,
  },
];


const competition_events = [
  {
    name: "Web Dev Workshop!",
    image: webdev1,
  },
  {
    name: "Web Dev Workshop!",
    image: webdev2,
  },
  {
    name: "Web Dev Workshop!",
    image: webdev2,
  },
];

const social_events = [
  
  {
    name: "O-Week",
    image: oweek,
  },{
    name: "Beach Day!",
    image: beachday,
  },
  {
    name: "Web Dev Workshop!",
    image: webdev2,
  },
];

const Events = () => {
  return (
    <Layout>
      <HeroComponent
        page={"Events"}
        heading={"What do we do?"}
        subheading={
          "Check out our past events, as well as what we have in store next."
        }
      ></HeroComponent>
      <CalloutBanner
        heading={"Coming up next..."}
        bgColor={"bg-blue"}
      ></CalloutBanner>
      <UpcomingEvent event = {up_coming_events}></UpcomingEvent>
      <CalloutBanner
        heading={"Past events"}
        bgColor={"bg-white"}
      ></CalloutBanner>
      <Gallery
        events={professional_event1}
        cardColour={"bg-blue"}
        eventType={"Networking"}
      ></Gallery>
      <Gallery
        events={professional_event2}
        cardColour={"bg-blue"}
        eventType={"Diverse Opportunities"}
      ></Gallery>
      <Gallery
        events={competition_events}
        cardColour={"bg-skyBlue"}
        eventType={"Competitions"}
      ></Gallery>
      <Gallery
        events={social_events}
        cardColour={"bg-darkBlue"}
        eventType={"Socials"}
      ></Gallery>
    </Layout>
  );
};

export default Events;
