import * as React from "react";

const CalloutBanner = ({ heading, bgColor }) => {
  const fontColor = bgColor.toLowerCase().includes("blue")
    ? "text-white"
    : "text-blue";

  return (
    <div
      className={`flex flex-col items-center justify-center ${bgColor} px-5 py-10 md:px-10 md:py-12 lg:py-20 lg:px-24 xl:px-32`}
    >
      <h2
        className={`text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-body font-bold ${fontColor} `}
      >
        {heading}
      </h2>
    </div>
  );
};

export default CalloutBanner;
