import * as React from "react";

const Gallery = ({ events, cardColour, eventType }) => {
  const event1 = events[0];
  const event2 = events[1];
  const event3 = events[2];

  return (
    <div className="px-5 py-10 pb-36 md:px-10 lg:px-24 xl:px-32">
      <div className="font-lexend-deca text-black text-5xl font-bold">
        {eventType}
      </div>
      <div className="flex flex-col lg:flex-row items-center">
        <div className="w-auto  lg:w-1/2 lg:pl-20 py-10">
          <img
            src={event1.image}
            className=" lg:w-[35em] lg:h-[35em] xl:w-[45em] xl:h-[45em] object-cover rounded-3xl"
          ></img>
          <div
            className={`${cardColour} absolute z-10 mt-10 lg:-ml-16 lg:-mt-36 px-5 py-4 text-white font-lexend-deca font-bold text-2xl w-[10em]`}
          >
            {event1.name}
          </div>
        </div>
        <div className="hidden lg:block w-1/2 pl-10 pt-40 lg:py-10">
          <div className="flex flex-col items-start">
            <div className="">
              <img
                src={event2.image}
                className="w-[25em] xl:w-[35em] object-cover rounded-3xl mb-10"
              ></img>
              <div
                className={`${cardColour} ml-64 -mt-72 xl:-mt-96 xl:ml-[22em] absolute z-10 px-5 py-4 text-white font-lexend-deca font-bold text-xl w-[8em]`}
              >
                {event2.name}
              </div>
            </div>
            <div>
              <img
                src={event3.image}
                className="w-[30em] xl:w-[40em] object-cover rounded-3xl"
              ></img>
              <div
                className={`${cardColour} absolute z-10 ml-96 -mt-8 xl:-mt-8 xl:ml-[21em] px-5 py-4 text-white font-lexend-deca font-bold text-xl w-[8em]`}
              >
                {event3.name}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center "></div>
      </div>
    </div>
  );
};

export default Gallery;
