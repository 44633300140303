import * as React from "react";

const Calendar = ({ day, month }) => {
  return (
    <div className="flex flex-col">
      <div className="bg-blue rounded-t-xl font-white font-lexend-deca text-white font-medium text-lg px-5 py-2 xl:text-xl xl:px-8 xl:py-2">
        {month}
      </div>
      <div className="bg-gray font-body font-bold text-center relative text-lg px-5 py-3 xl:px-5 xl:py-4 rounded-b-xl xl:text-3xl">
        {day}
      </div>
    </div>
  );
};

export default Calendar;
